import "./App.css";
import "./lang/index";
import { lazy, Suspense } from "react";
import {
  Route,
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
} from "react-router-dom";
const UserDashboard = lazy(() => import("./pages/Client"));
const AdminDashboard = lazy(() => import("./pages/Admin"));
const AdminLayout = lazy(() => import("./layout/AdminLayout"));
const ClientLayout = lazy(() => import("./layout/ClientLayout"));
const RootLayout = lazy(() => import("./layout/RootLayout"));
const AuthLayout = lazy(() => import("./layout/AuthLayout"));
const PublicLayout = lazy(() => import("./layout/PublicLayout"));
const Home = lazy(() => import("./pages/Public/Home"));
const About = lazy(() => import("./pages/Public/AboutUS"));
const Login = lazy(() => import("./pages/auth/Login"));
const Signup = lazy(() => import("./pages/auth/Signup"));
const Loading = lazy(() => import("./components/Shared/Loading"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route path="/" element={<PublicLayout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
      </Route>

      <Route path="/admin" element={<AdminLayout />}>
        <Route index element={<AdminDashboard />}></Route>
      </Route>
      <Route path="/client" element={<ClientLayout />}>
        <Route index element={<UserDashboard />}></Route>
      </Route>

      <Route path="auth" element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
      </Route>
    </Route>
  )
);

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
