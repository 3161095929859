import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as en from "./en.json";
import * as ar from "./ar.json";
import * as Yup from "yup"

const resources = {
  en: { translation: en },
  ar: { translation: ar },
} as const;


i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("lang") || "en",
  fallbackLng: "en",
  supportedLngs: ["ar", "en"],
  interpolation: {
    escapeValue: false,
  },
});

document.documentElement.lang = i18n.language;
document.documentElement.dir = i18n.dir(i18n.language);


Yup.setLocale({
  mixed:{
    required: i18n.t("validations.required")
  },
})

export default i18n;
